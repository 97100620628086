const Checkmark = ({ ...rest }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="12" fill="#5EB9B2" />
    <path
      d="M15.771 10.286l-4.525 5.485-3.017-2.693"
      stroke="#ffffff"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default Checkmark;
