import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Layout from 'templates/Layout';
import { Section, Inner } from 'components';
import Form from 'modules/Form';

function BookPage({ data: { datoCmsAirtableForm } }) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newStartupFunnelStarted',
      });
    }
  }, []);

  return (
    <Layout
      seo={{
        title: datoCmsAirtableForm.headline,
        description: datoCmsAirtableForm.description,
      }}
    >
      <Section>
        <Inner css={tw`sm:max-w-3xl pt-44`}>
          <Form
            {...datoCmsAirtableForm}
            body={
              <div css={tw`mb-12`}>
                <h2 css={tw`font-semibold mb-4 text-lg`}>Our startup criteria:</h2>
                <ul css={tw`list-disc pl-5`}>
                  <li css={tw`list-item list-disc mb-2`}>
                    The startup is a maximum of 10 years old, or in the case of a scaleup, it has
                    raised venture capital or been listed on the stock exchange within the last 5
                    years
                  </li>
                  <li css={tw`list-item list-disc mb-2`}>
                    Digital is a key element in the business model, and the company’s digital
                    transformative value proposition is based on scalable digital technologies.
                  </li>
                  <li css={tw`list-item list-disc mb-2`}>
                    The company is registered in Denmark – it has a CVR number.
                  </li>
                  <li css={tw`list-item list-disc`}>
                    The company is founded in Denmark, and its main activity is in Denmark.
                  </li>
                </ul>
              </div>
            }
          />
        </Inner>
      </Section>
    </Layout>
  );
}

export default BookPage;

export const formQuery = graphql`
  {
    datoCmsAirtableForm(metaTitle: { eq: "Get listed" }) {
      heading
      description
      airtableBaseId
      airtableTableId
      inputBlocks {
        ... on DatoCmsGroupLabel {
          model {
            apiKey
          }
          label
        }
        ... on DatoCmsToggleShortForm {
          model {
            apiKey
          }
          id: originalId
          airtableColumnLabel
          label
          helpTextNode {
            childMarkdownRemark {
              html
            }
          }
          includeInShortForm
          optional
          options {
            label
            shortForm
            hideSubmit
            airtableItemId
          }
        }
        ... on DatoCmsInput {
          model {
            apiKey
          }
          id: originalId
          airtableColumnLabel
          label
          inputType
          helpTextNode {
            childMarkdownRemark {
              html
            }
          }
          includeInShortForm
          optional
          widthSize
        }
        ... on DatoCmsSelectButton {
          model {
            apiKey
          }
          id: originalId
          airtableColumnLabel
          label
          helpTextNode {
            childMarkdownRemark {
              html
            }
          }
          options {
            airtableItemId
            label
          }
          optional
          hasOtherOption
          includeInShortForm
          other {
            airtableColumnLabel
            label
            helpTextNode {
              childMarkdownRemark {
                html
              }
            }
            inputType
          }
        }
        ... on DatoCmsGdprCheckbox {
          model {
            apiKey
          }
          id: originalId
          airtableColumnLabel
          label
          acceptLabel
          includeInShortForm
        }
        ... on DatoCmsSelectCountry {
          model {
            apiKey
          }
          id: originalId
          label
          airtableColumnLabel
          includeInShortForm
          optional
        }
      }
    }
  }
`;
