import React, { useEffect } from 'react';
import tw from 'twin.macro';
import RadioButton from 'modules/Form/RadioButton';
import { Col, Label } from 'modules/Form';
import { useFormContext } from 'contexts/form';

const ToggleShortForm = ({ airtableColumnLabel, label, options }) => {
  const { values, setValues, setIsShortForm } = useFormContext();

  useEffect(() => {
    if (values[airtableColumnLabel].length === 0) {
      const initialValue = options.find(({ shortForm }) => shortForm !== true).airtableItemId;
      setValues({ ...values, [airtableColumnLabel]: initialValue });
    }
  }, [values]);

  return (
    <Col>
      <Label>{label}</Label>
      <div tw="mt-4">
        {options.map(({ airtableItemId, label, shortForm }) => (
          <RadioButton
            id={airtableItemId}
            onClick={() => {
              setValues({ ...values, [airtableColumnLabel]: airtableItemId });
              setIsShortForm(shortForm);
            }}
            isActive={values[airtableColumnLabel] === airtableItemId}
            key={airtableItemId}
          >
            {label}
          </RadioButton>
        ))}
      </div>
    </Col>
  );
};

export default ToggleShortForm;
