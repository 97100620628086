import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { submitForm } from 'utils/airtable';
import { initialValues, isFieldValid } from 'modules/Form/helpers';

const FormContext = React.createContext();

function FormProvider({ children, blocks, airtable, successUrl = '/get-listed/confirmation/' }) {
  const initialState = initialValues(blocks);
  const [values, setValues] = useState(initialState);
  const [submissionStatus, setSubmissionStatus] = useState([]);
  const [isShortForm, setIsShortForm] = useState(false);
  const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    let fieldsValidated = {};

    blocks.map(
      ({ model: { apiKey }, airtableColumnLabel, inputType, optional, includeInShortForm }) => {
        if (apiKey === 'group_label') return null;
        const validateShortForm = isShortForm && includeInShortForm;
        if ((validateShortForm || !isShortForm) && !optional) {
          fieldsValidated = {
            ...fieldsValidated,
            [airtableColumnLabel]: isFieldValid(inputType || apiKey, values[airtableColumnLabel]),
          };
        }
      }
    );

    const allFieldsValid = Object.values(fieldsValidated).every((f) => f === true);
    setSubmitButtonEnabled(allFieldsValid);
  }, [values]);

  useEffect(() => {
    if (submissionStatus[0] === 'success') {
      navigate(successUrl, {
        state: {
          isShortForm,
        },
      });
    }
  }, [submissionStatus, successUrl]);

  const onSubmitForm = () =>
    submitForm(airtable.airtableBaseId, airtable.airtableTableId, values, setSubmissionStatus);

  const store = {
    values,
    setValues,
    isShortForm,
    setIsShortForm,
    onSubmitForm,
    submitButtonEnabled,
    submissionStatus,
  };

  return <FormContext.Provider value={store}>{children}</FormContext.Provider>;
}

export const useFormContext = () => useContext(FormContext);

export default FormProvider;
