import React, { useState } from 'react';
import tw from 'twin.macro';
import { Col, HelpText, Label, InvalidText } from 'modules/Form';
import { useFormContext } from 'contexts/form';
import Checkmark from 'icons/Checkmark';
import { isFieldValid, invalidFieldText } from './helpers';

const inputStyles = tw`mt-2 bg-white-25 border-white-50 text-white focus:border-green outline-none p-3 text-xl w-full`;
const validInputStyles = tw`border-transparent bg-white-10`;
const checkmarkStyles = tw`h-10 w-10 absolute top-5 right-3`;
const width25Styles = tw`md:w-1/4`;
const width50Styles = tw`md:w-1/2`;
const width75Styles = tw`md:w-3/4`;

function Input({ airtableColumnLabel, id, label, helpTextNode, inputType, optional, widthSize }) {
  const { values, setValues } = useFormContext();
  const [valid, setValid] = useState();
  const Tag = inputType === 'textarea' ? 'textarea' : 'input';
  if (inputType !== 'text' || inputType !== 'textarea') inputType = inputType;

  const key = airtableColumnLabel;
  const value = values[key];

  let widthStyles;

  switch (widthSize) {
    case '25':
      widthStyles = width25Styles;
      break;
    case '50':
      widthStyles = width50Styles;
      break;
    case '75':
      widthStyles = width75Styles;
      break;
    default:
      widthStyles = null;
      break;
  }

  return (
    <Col css={[widthStyles]}>
      <Label htmlFor={id}>{label}</Label>
      {helpTextNode && (
        <HelpText dangerouslySetInnerHTML={{ __html: helpTextNode.childMarkdownRemark.html }} />
      )}
      <div tw="relative">
        <Tag
          id={id}
          type={inputType}
          value={value}
          css={[inputStyles, valid && validInputStyles]}
          onChange={(e) => {
            setValues({
              ...values,
              [key]: inputType === 'number' ? parseInt(e.target.value, 10) : e.target.value,
            });
          }}
          onBlur={() => setValid(() => isFieldValid(inputType, value))}
          onFocus={() => setValid()}
        />
        {valid && <Checkmark css={checkmarkStyles} />}
        {valid === false && !optional && <InvalidText>{invalidFieldText(inputType)}</InvalidText>}
      </div>
    </Col>
  );
}

export default Input;
