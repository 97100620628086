import tw, { css, styled } from 'twin.macro';

const RadioButton = styled.button(({ isActive }) => [
  tw`relative pl-14 flex items-center h-16 text-left`,
  css`
    &:focus {
      &:before {
        ${tw`border-green`}
      }
    }
  `,
  css`
    &:before {
      content: '';
      ${tw`absolute left-0 bg-white-50 w-10 h-10 rounded-full border-2 border-solid border-white-25`}
    }
  `,
  css`
    &:after {
      content: '';
      ${tw`absolute left-1 bg-green w-8 h-8 rounded-full transform scale-0 transition-transform`}
    }
  `,
  isActive &&
    css`
      &:after {
        ${tw`transform scale-100`}
      }
    `,
]);

export default RadioButton;
