import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Col, Row, Label, HelpText, InvalidText } from 'modules/Form';
import { isFieldValid, invalidFieldText } from './helpers';
import { useFormContext } from 'contexts/form';
import Input from 'modules/Form/Input';
import Checkmark from 'icons/Checkmark';

const inputStatusStyles = tw`absolute bottom-0 left-0`;
const checkmarkStyles = tw`h-10 w-10`;

const Button = styled.button(({ isActive }) => [
  tw`font-semibold border border-solid border-white-50 rounded-full bg-white-25 py-1 px-2 mr-2 mb-3 text-xl sm:(py-2 px-5 mb-0) hover:border-white focus:border-green`,
  isActive && tw`bg-green-75 text-white`,
]);

const SelectButton = ({ label, onClick, isActive, ...rest }) => (
  <Button onClick={onClick} isActive={isActive} {...rest}>
    {label}
  </Button>
);

const SelectButtons = ({
  model: { apiKey },
  airtableColumnLabel,
  label,
  options,
  helpTextNode,
  optional,
  hasOtherOption,
  other,
}) => {
  const { values, setValues } = useFormContext();
  const [valid, setValid] = useState();

  const onSelect = (id, label) => {
    const arr = values[id]?.length > 0 ? [...values[id]] : [];
    arr.includes(label) ? arr.splice(arr.indexOf(label), 1) : arr.push(label);
    setValues({ ...values, [id]: arr });
    setValid(() => isFieldValid(apiKey, arr));
  };

  const otherIsSelected = hasOtherOption && values[airtableColumnLabel].includes('Other');

  return (
    <>
      <Col>
        <Label>{label}</Label>
        {helpTextNode && (
          <HelpText dangerouslySetInnerHTML={{ __html: helpTextNode.childMarkdownRemark.html }} />
        )}
        <Row tw="flex-wrap gap-4 mt-3 pb-12 relative">
          {options.map(({ label, airtableItemId }) => {
            const value = values[airtableColumnLabel];
            return (
              <SelectButton
                label={label}
                isActive={value.includes(airtableItemId)}
                onClick={() => onSelect(airtableColumnLabel, airtableItemId)}
                key={label}
              />
            );
          })}
          {valid && <Checkmark css={[checkmarkStyles, inputStatusStyles]} />}
          {valid === false && !optional && (
            <InvalidText css={[inputStatusStyles, tw`bottom-2`]}>
              {invalidFieldText(apiKey)}
            </InvalidText>
          )}
        </Row>
      </Col>
      {otherIsSelected && <Input {...other} />}
    </>
  );
};
export default SelectButtons;
