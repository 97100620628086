import React, { useRef, useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import AccordionArrow from 'components/Icons/AccordionArrow';
import countries from 'consts/countries.json';
import { Col, InvalidText } from 'modules/Form';
import { isFieldValid, invalidFieldText } from './helpers';
import { useFormContext } from 'contexts/form';
import Checkmark from 'icons/Checkmark';

// const countries = countriesJSON.map(({ name }) => name);

const selectStyles = css`
  ${tw`border border-solid border-white bg-transparent text-white text-xl px-2 py-1 font-semibold pr-12 w-full outline-none focus:border-green`}
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  option {
    ${tw`text-xs font-normal`}
  }
`;

const validSelectStyles = css`
  ${tw`bg-white-10`}
  select {
    ${tw`border-transparent`}
  }
  svg {
    ${tw`text-white-50`}
  }
`;

const checkmarkStyles = tw`h-10 w-10 absolute top-1 -right-12`;

const SelectCountry = ({ optional, model: { apiKey } }) => {
  const { values, setValues } = useFormContext();
  const ref = useRef();
  const [valid, setValid] = useState();

  const onChange = () => {
    const select = ref.current;
    const { value } = select.options[select.selectedIndex];
    setValues({ ...values, Country: value });
    setValid(() => isFieldValid(apiKey, value));
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && values.Country === 'Select') {
      fetch(
        `https://api.ipapi.com/api/check?access_key=${process.env.GATSBY_IP_API_KEY}&fields=continent_name,country_code,country_name,location`
      )
        .then((res) => res.json())
        .then((response) => {
          const country = countries.find(({ code }) => code === response.country_code);
          if (response.success !== false && country)
            setValues({ ...values, Country: country.name });
        });
    }
  }, [values]);

  return (
    <Col tw="mb-10 items-start">
      <div
        tw="relative inline-block mb-2 mt-4 inline-block bg-white-25"
        css={valid && validSelectStyles}
      >
        <select onChange={onChange} ref={ref} css={selectStyles} value={values.Country}>
          {countries.map(({ name, code }) => (
            <option key={code}>{name}</option>
          ))}
        </select>
        <AccordionArrow tw="absolute transform rotate-180 -translate-y-1/2 right-4 top-1/2 pointer-events-none" />
        {valid && <Checkmark css={checkmarkStyles} />}
      </div>
      {valid === false && !optional && (
        <InvalidText tw="block mt-0">{invalidFieldText(apiKey)}</InvalidText>
      )}
    </Col>
  );
};

export default SelectCountry;
