import React from 'react';
import tw, { css } from 'twin.macro';
import { Col, Row } from 'modules/Form';
import { useFormContext } from 'contexts/form';

export const Checkbox = tw.div`outline-none relative inline-block text-transparent cursor-pointer border border-white border-solid h-6 w-6 mt-1 focus:border-green hover:border-green`;
export const checkedStyles = css`
  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #5ec2bb;
    top: 3px;
    left: 3px;
  }
`;

const GDPR = ({ acceptLabel, label, airtableColumnLabel }) => {
  const { values, setValues } = useFormContext();
  const key = airtableColumnLabel;

  return (
    <Col tw="mt-4">
      <p tw="text-xs opacity-90">{label}</p>
      <Row tw="items-center">
        <Checkbox
          id="gdpr"
          css={values[key] && checkedStyles}
          role="button"
          aria-label="consent"
          tabIndex="0"
          onClick={() => setValues({ ...values, [key]: !values[key] })}
          onKeyPress={() => setValues({ ...values, [key]: !values[key] })}
        />
        <span tw="ml-4 font-bold">{acceptLabel}</span>
      </Row>
    </Col>
  );
};

export default GDPR;
