import React from 'react';
import tw from 'twin.macro';
import { PageTitle } from 'components';
import Input from 'modules/Form/Input';
import GDPR from 'modules/Form/GDPR';
import SelectButtons from 'modules/Form/SelectButtons';
import SelectCountry from 'modules/Form/SelectCountry';
import ToggleShortForm from 'modules/Form/ToggleShortForm';
import SubmitButton from 'modules/Form/SubmitButton';
import FormProvider, { useFormContext } from 'contexts/form';

const Divider = tw.hr`text-white-25 mt-2 mb-3`;
const GroupLabel = tw.h2`mt-8 font-bold text-white-75`;
export const Col = tw.div`flex flex-col mb-6`;
export const Row = tw.div`flex flex-row`;
export const Label = tw.label`text-base font-semibold block`;
export const HelpText = tw.span`italic opacity-75`;
export const InvalidText = tw.span`block mt-2 text-red font-bold`;

const Fields = ({ blocks }) => {
  const { isShortForm } = useFormContext();

  return blocks.map((props, i) => {
    const {
      model: { apiKey },
      label,
      includeInShortForm,
    } = props;
    const key = apiKey + i;

    if (includeInShortForm || !isShortForm || apiKey === 'group_label') {
      switch (apiKey) {
        case 'toggle_short_form':
          return <ToggleShortForm {...props} key={key} />;
        case 'input':
          return <Input {...props} key={key} />;
        case 'select_button':
          return <SelectButtons {...props} key={key} />;
        case 'group_label':
          return (
            <>
              <GroupLabel>{label}</GroupLabel>
              <Divider />
            </>
          );
        case 'select_country':
          return (
            <>
              <Label>{label}</Label>
              <SelectCountry {...props} key={key} />
            </>
          );
        case 'gdpr_checkbox':
          return <GDPR {...props} key={key} />;
        default:
          return null;
      }
    }
  });
};

function Form({
  heading,
  description,
  inputBlocks,
  airtableBaseId,
  airtableTableId,
  successUrl,
  body,
}) {
  return (
    <FormProvider
      blocks={inputBlocks}
      airtable={{ airtableBaseId, airtableTableId }}
      successUrl={successUrl}
    >
      <Col>
        <PageTitle tw="w-10/12 mb-5">{heading}</PageTitle>
        <p tw="mb-12">{description}</p>
        {body || null}
        <Fields blocks={inputBlocks} />
        <SubmitButton label="Send Request" failedMessage="" />
      </Col>
    </FormProvider>
  );
}

export default Form;
