import { validateEmail, validateURL } from 'utils';

export const isFieldValid = (type, value) => {
  switch (type) {
    case 'toggle_short_form':
      return value.length > 0;
    case 'email':
      return validateEmail(value);
    case 'gdpr_checkbox':
      return value;
    case 'select_country':
      return value !== 'Select';
    case 'url':
      return validateURL(value);
    case 'number':
      return !Number.isNaN(value);
    case 'tel':
    case 'text':
    case 'textarea':
    case 'select_button':
    default:
      return value.length > 0;
  }
};

export const invalidFieldText = (type) => {
  switch (type) {
    case 'email':
      return 'Invalid email';
    case 'gdpr':
      return 'Consent required';
    case 'url':
      return 'Invalid URL, please include https://';
    case 'text':
    case 'textarea':
    case 'tel':
    case 'select_button':
    case 'select_country':
    default:
      return 'Field required';
  }
};

const initialValue = (apiKey) => {
  switch (apiKey) {
    case 'select_country':
      return 'Select';
    case 'select_button':
      return [];
    case 'gdpr_checkbox':
      return false;
    default:
      return '';
  }
};

export const initialValues = (blocks) => {
  let obj = {};

  blocks.map(({ model, airtableColumnLabel, other }) => {
    const { apiKey } = model;

    if (apiKey === 'group_label') return null;

    if (other) {
      obj = {
        ...obj,
        [other.airtableColumnLabel]: '',
      };
    }
    obj = { ...obj, [airtableColumnLabel]: initialValue(apiKey) };
  });

  return obj;
};
