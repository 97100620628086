import React from 'react';
import tw, { css } from 'twin.macro';
import TiltButton from 'components/TiltButton';
import { useFormContext } from 'contexts/form';

const submitButtonStyles = css`
  ${tw`mt-10 text-xl mx-auto`}
  button { ${tw`px-12`}
`;

function SubmitButton({ label, failedMessage, ...rest }) {
  const {
    onSubmitForm,
    submitButtonEnabled,
    submissionStatus: [status, message],
  } = useFormContext();

  return (
    <>
      {status === 'failed' && (
        <div tw="text-center">
          <h1 tw="text-red">{`Error: ${message}`}</h1>
          {failedMessage ? (
            <p tw="mt-2">
              Sorry for the inconvenience 😔
              <br />
              please contact us at{' '}
              <a href="mailto: delegations@digitalhubdenmark.dk" tw="text-green font-semibold">
                delegations@digitalhubdenmark.dk
              </a>
            </p>
          ) : (
            failedMessage
          )}
        </div>
      )}
      <TiltButton
        label={label}
        onClick={() => onSubmitForm()}
        css={submitButtonStyles}
        disabled={!submitButtonEnabled}
        {...rest}
      />
    </>
  );
}

export default SubmitButton;
